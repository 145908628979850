import React, { Component } from 'react';

import MailchimpSubscribe from "react-mailchimp-subscribe"

import './SubscribeForm.css';

class SubscribeForm extends Component {
  render() {
    const url =
      "https://admend.us14.list-manage.com/subscribe/post?u=eff981542996d63dac7c8228a&id=a516e4ac46";
    return (
      <div className="subscribe-div center">
        <h6 className="white-text center">Get early access to SortWork updates:</h6>
        <MailchimpSubscribe url={url} />
      </div>
    );
  }
}

export default SubscribeForm

import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip'
import Select from 'react-select';
import './JobList.css';
import Job from '../Job/Job';
import JobFilters from '../JobFilters/JobFilters';

import { load } from '../../helpers/spreadsheet';

const PAGE_LIMIT = 20;

const CATEGORY_OPTIONS = [
  { value: 'Architecture and engineering', label: 'Architecture and engineering' },
  { value: 'Arts, design, entertainment, sports, and media', label: 'Arts, entertainment, sports, media' },
  { value: 'Business and financial operations', label: 'Business and financial operations'},
  { value: 'Building and grounds cleaning and maintenance', label: 'Cleaning and maintenance'},
  { value: 'Community and social service', label: 'Community and social service'},
  { value: 'Computer and mathematical', label: 'Computer and mathematical'},
  { value: 'Construction and extraction', label: 'Construction and extraction'},
  { value: 'Education, training, and library', label: 'Education, training, and library'},
  { value: 'Farming, fishing, and forestry', label: 'Farming, fishing, and forestry'},
  { value: 'Food preparation and serving related', label: 'Food preparation and serving'},
  { value: 'Healthcare', label: 'Healthcare'},
  { value: 'Healthcare support', label: 'Healthcare support'},
  { value: 'Installation, maintenance, and repair', label: 'Installation, maintenance, and repair'},
  { value: 'Legal', label: 'Legal'},
  { value: 'Life, physical, and social science', label: 'Life, physical, and social science'},
  { value: 'Management', label: 'Management'},
  { value: 'Office and administrative support', label: 'Office and administrative support'},
  { value: 'Personal care and service', label: 'Personal care and service'},
  { value: 'Production', label: 'Production'},
  { value: 'Protective service', label: 'Protective service'},
  { value: 'Sales and related', label: 'Sales and related'},
  { value: 'Transportation and material moving', label: 'Transport and material moving'},
];

class JobList extends Component {

  state = {
    loading: true,
    error: null,
    jobs: [],
    page: 0,
    pageCount: 0,
    search: '',
    category: '',
    results: 0,
    sort: 'overall_index',
    sort_desc: true,
    show_legend: false
  };

  renderJobTable = () => (
    <div className="col s12 m12">
      <ReactTooltip place="bottom" type="info" effect="solid" multiline={true} />
      <table className="striped">
        <thead>
          <tr className="table-headings">
              <th>
                <span className={this.state.sort === 'title_index' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="title_index" onClick={this.handleSortSelect}>Occupation
                </span>
              </th>
              <th>
                <span className={this.state.sort === 'median_wage' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="median_wage" onClick={this.handleSortSelect}>Median wage
                </span>
              </th>
              <th>
                <span className={this.state.sort === 'emp2016' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="emp2016" onClick={this.handleSortSelect}>Employment
                </span>
              </th>
              <th>
                <span className={this.state.sort === 'delta_10' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="delta_10" onClick={this.handleSortSelect}>Growth &Delta;
                </span>
              </th>
              <th>
                <span className={this.state.sort === 'delta_per_10' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="delta_per_10" onClick={this.handleSortSelect}>Growth %
                </span>
              </th>
              <th>
                <span className={this.state.sort === 'req_education_level' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="req_education_level" onClick={this.handleSortSelect}>Education Required
                </span>
              </th>
              <th>
                <span className={this.state.sort === 'related_exp_level' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="related_exp_level" onClick={this.handleSortSelect}>Experience
                </span>
              </th>
              <th>
                <span className={this.state.sort === 'overall_index' ? 'heading-sort-link active-heading' : 'heading-sort-link'} id="overall_index" onClick={this.handleSortSelect}>Score
                <span className="data-superscript"><sup>1</sup></span>
                </span>
              </th>
          </tr>
        </thead>
        
        <tbody>
          {this.state.currentResults.length ? (
            this.state.currentResults.map((job, index) => (
              <Job
                key={job.bls_code}
                job={job}
                index={index}
                sortOption={this.state.sort}
                page={this.state.page}
                sort_desc={this.state.sort_desc}
              />
            ))
          ) : (
            <div> </div>
          )}
        </tbody>
      </table>
    </div>
  );

  componentDidMount() {
  	window.gapi.load("client", this.start);
  }

  start = () => {
    window.gapi.client
      .init({
        apiKey: 'AIzaSyAQOp5Oet9gCDn2qPFJ9ztvz6mOtEGEqt4',
        discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest'],
      })
      .then(() => {
        load(this.onLoad);
      });
  };

  onLoad = (data, error) => {
    if (data) {
      const { page } = this.state;
      this.setState({
        jobs: data.jobs,
        results: data.jobs.length,
        currentResults: data.jobs.slice(page, PAGE_LIMIT),
        pageCount: data.jobs.length / PAGE_LIMIT,
        loading: false,
      });
    } else {
      this.setState({ error, loading: false });
    }

  };

  toggleLegendPane = (e) =>{
    e.preventDefault();
    this.setState({
      show_legend: !this.state.show_legend
    });
  }

  handleSortSelect = e => {
    const selected = e.target.id;

    if (selected === this.state.sort){
      if(this.state.sort_desc){
        var jobs = this.state.jobs.sort((a, b) => a[selected] - b[selected]);
        this.setState({ sort_desc: false });
      } else{
        jobs = this.state.jobs.sort((a, b) => b[selected] - a[selected]);
        this.setState({ sort_desc: true });
      }
    } else{
      jobs = this.state.jobs.sort((a, b) => b[selected] - a[selected]);
      this.setState({ sort_desc: true });
    }
    
    const searchResults = this.findMatches(this.state.search, jobs);
    var filteredJobs = searchResults;
    if(this.state.category !== ''){
      filteredJobs = searchResults.filter(job => job.occupation_category === this.state.category.value);
    }
    
    this.setState({
      jobs,
      currentResults: filteredJobs.slice(0, PAGE_LIMIT),
      sort: selected,
      page: 0
    });
    
  }

  handleChangePage = data => {
    const { selected } = data;

    const subresults = this.state.subresults;
     
    if(subresults && subresults !== '') {
      this.setState({
        currentResults: this.state.subresults.slice(selected * PAGE_LIMIT, selected * PAGE_LIMIT + PAGE_LIMIT),
        page: selected,
      });

    } else{
      this.setState({
        currentResults: this.state.jobs.slice(selected * PAGE_LIMIT, selected * PAGE_LIMIT + PAGE_LIMIT),
        page: selected,
      });
    }

  };

  handleFilterByCategory = selected => {
    
    if(selected !== null && selected !== '') {

      const filteredJobs = this.state.jobs.filter(job => job.occupation_category === selected.value);
      const searchResults = this.findMatches(this.state.search, filteredJobs);

      this.setState({
        page: 0,
        results: searchResults.length,
        pageCount: Math.ceil(searchResults.length / PAGE_LIMIT),
        currentResults: searchResults.slice(0, PAGE_LIMIT),
        category: selected,
        subresults: searchResults
      })

    } else {
      
      const searchResults = this.findMatches(this.state.search, this.state.jobs);

      this.setState({
        page: 0,
        results: searchResults.length,
        pageCount: Math.ceil(searchResults.length / PAGE_LIMIT),
        currentResults: searchResults.slice(0, PAGE_LIMIT),
        category: '',
        subresults: ''
      })
    }


  }

  handleSearch = e => {
    const searchValue = e.target.value;
    
    if(this.state.category !== ''){
      const filteredJobs = this.state.jobs.filter(job => job.occupation_category === this.state.category.value);
      var searchResults = this.findMatches(searchValue, filteredJobs);
    }else{
      searchResults = this.findMatches(searchValue, this.state.jobs);
    }

    this.setState({
      page: 0,
      results: searchResults.length,
      pageCount: Math.ceil(searchResults.length / PAGE_LIMIT),
      currentResults: searchResults.slice(0, PAGE_LIMIT),
      subresults: searchResults,
      search: searchValue,
    });
  };

  findMatches = (wordToMatch, jobs) =>
    jobs.filter(job => {
      const regex = new RegExp(wordToMatch, 'gi');
      return job.title.match(regex);
  });

  toggleLegendPane = (e) =>{
    e.preventDefault();
    this.setState({
      show_legend: !this.state.show_legend
    });
  }

  render() {
    
    if (this.state.loading) {
      return (
        <div className="loading-div center">
          
          <h4 className="loading-text">Loading Employment Data...</h4>

          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div><div className="gap-patch">
                <div className="circle"></div>
              </div><div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>

        </div>
      );
    }
    return (
      <div>
        
        <div className="notes-row">
            <a href="#" onClick={this.toggleLegendPane} className="data-notes">show/hide data notes</a>

            {this.state.show_legend ? (
              <div className="row center">
                <div className="legend-col">
                  <p><b>OCCUPATION</b>: From BLS 2018 National Employment Matrix</p>
                  <p><b>MEDIAN WAGE</b>: Median annual wage (US$, 2018) (est.) = estimated</p>
                  <p><b>EMPLOYMENT</b>: Total U.S. Employment (2018)</p>
                  <p><b>GROWTH &Delta;</b>: Raw Employment change (# of jobs, 2018-2028)</p>
                  <p><b>GROWTH %</b>: Employment change (% change, 2018-2028)</p>
                  <p><b>EDUCATION</b>: Typical education needed for entry</p>
                  <p><b>RELATED EXP.</b>: Years of work experience required for entry (not including internship/residency or training)</p>
                  <p><b>SCORE</b>: Hypothetical "Most Promising" score based on median wage and growth projections. Purely academic.</p>
                </div>
            </div>
            ) : (<div></div>)}


        </div>

        <div className="row options-row">
          <div className="options-wrapper">
            
            <label className="search-label">Search jobs:</label>
            <input
              type="text"
              name="search"
              id="search"
              className="search-box"
              placeholder='e.g. "teacher" or "physician" '
              value={this.state.search}
              onChange={e => this.handleSearch(e)}
            />
          </div>

          <div className="options-wrapper">
            
            <label className="search-label">Filter:</label>
              <Select
                options={CATEGORY_OPTIONS}
                className="category-select"
                isClearable={true}
                isSearchable={false}
                value={this.state.category}
                onChange={this.handleFilterByCategory}
                placeholder="Select job category..."
              />
          </div>

        </div> 
        {this.state.results > 0 ? 
          (
            <div>
              
            <p className="center sort-caption grey-text text-darken-2">(click on column headings to sort the table)</p>
      
            <div className="row">
              {this.renderJobTable()}
            </div>

            <div className="row center">
              <ReactPaginate
                previousLabel={
                  <i className="material-icons">chevron_left</i>
                }
                nextLabel={
                  <i className="material-icons">chevron_right</i>
                }
                breakLabel={<button onClick={e => e.preventDefault()}>...</button>}
                breakClassName="break-pages"
                forcePage={this.state.page}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handleChangePage}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
              />
            </div>

            <p className="center record-counter"><b>Showing {this.state.page * PAGE_LIMIT + 1}-{this.state.page * PAGE_LIMIT + this.state.currentResults.length} of {this.state.results} occupations</b></p>

        </div>

          ) : (
             <p className="center"><b>No records found</b> </p>
          )
        }
        
      </div>
    );
  }
}

export default JobList;
import React from 'react';
import './Job.css';

const colorPickerScore = (value) => {
    
    if (value >= 80){
        return "green-text";
    } else if ( value >= 60 && value < 80 ){
        return "light-green-text text-accent-4";
    } else if ( value >= 40 && value < 60 ){
        return "amber-text text-accent-4";
    } else if ( value >= 20 && value < 40 ){
        return "orange-text text-accent-4";
    } else if ( value >= 0 && value < 20 ){
        return "red-text text-accent-4";
    }
};

const colorPickerWage = (value) => {
    
    if (value >= 100000){
        return "green-text";
    } else if ( value >= 70000 && value < 100000 ){
        return "light-green-text text-accent-4";
    } else if ( value >= 50000 && value < 70000 ){
        return "amber-text text-accent-4";
    } else if ( value >= 30000 && value < 50000 ){
        return "orange-text text-accent-4";
    } else if ( value >= 0 && value < 30000 ){
        return "red-text text-accent-4";
    }
};

const colorPickerGrowth = (value) => {
    
    if (value >= 10){
        return "green-text";
    } else if ( value >= 5 && value < 10 ){
        return "light-green-text text-accent-4";
    } else if ( value >= 2 && value < 5 ){
        return "amber-text text-accent-4";
    } else if ( value >= 0 && value < 2 ){
        return "orange-text text-accent-4";
    } else if ( value < 0 ){
        return "red-text text-accent-4";
    }
};

const JobRow = ({ job, index, sortOption, page, sort_desc  }) => (
      <tr className="table-rows">
        <td className="table-occupation">{job.title}</td>
        <td className={sortOption === 'median_wage' ? 'rank-value ' + colorPickerWage(job.median_wage) : colorPickerWage(job.median_wage)}>
            ${job.median_wage.toLocaleString()}{job.median_wage_ex}{job.median_wage_h !== 'NA' ? <span> (est.)</span> : ''}
        </td>
        <td className={sortOption === 'emp2016' ? 'rank-value ' : ''}>
            {job.emp2016.toLocaleString()}
        </td>
        <td className={sortOption === 'delta_10' ? 'rank-value ' : ''}>
            {job.delta_10.toLocaleString()}
        </td>
         <td className={sortOption === 'delta_per_10' ? 'rank-value ' + colorPickerGrowth(job.delta_per_10) : colorPickerGrowth(job.delta_per_10)}>
            {job.delta_per_10.toFixed(1)}%
        </td>
        <td className='table-education'>
            {job.req_education}
        </td>
        <td className={sortOption === 'related_exp_level' ? '' : ''}>
            {job.related_exp}
        </td>
        <td className={sortOption === 'overall_index' ? 'rank-value ' + colorPickerScore(job.overall_index) : colorPickerScore(job.overall_index)}>
            {job.overall_index.toFixed(1)}
        </td>
      </tr>
);

export default JobRow;

import React, { Component } from 'react';
import './App.css';

import JobList from './components/JobList/JobList';
import Footer from '../src/components/Footer/Footer';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-82155001-5');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
	  [
  	  <header key="1">
        <div className="header-div">
          <h2 className="brand-logo">What Will Jobs Look Like in 2028?</h2>
          <h6 className="brand-subtitle">Data source: U.S. Bureau of Labor Statistics (bls.gov)</h6>
        </div>
  	  </header>,

  	  <main key="2">
  	    <div className="container">
          <JobList />
        </div>
  	  </main>,

  	  <footer key="3">
  	  	<Footer />
  	  </footer>

    ]
    );
  }
}

export default App;

import React, { Component } from 'react';

import './Footer.css';
import '../../index.css';
import SubscribeForm from '../SubscribeForm/SubscribeForm';

class Footer extends Component {

  render() {
    
    return (
        <footer className="page-footer blue darken-3">
          
        {/* <div className="container">
            <SubscribeForm />
        </div> */}

          <div className="footer-copyright">
            <div className="container center">
            Questions? Comments? <b><a className="grey-text text-lighten-4" href="mailto:keeter@gmail.com"><u>Say hello!</u></a></b>
            </div>
          </div>
        </footer>
    );
  }
}

export default Footer;
import config from "../config";

export function load(callback) {
  window.gapi.client.load("sheets", "v4", () => {
    window.gapi.client.sheets.spreadsheets.values
      .get({
        spreadsheetId: config.spreadsheetId,
        range: "Sheet1!A2:AD",
        valueRenderOption: "UNFORMATTED_VALUE"
      })
      .then(
        response => {
          const data = response.result.values;
          let jobs =
            data.map(job => ({
              title: job[0],
              title_index: job[1],
              bls_code: job[2],
              occupation_category: job[4],
              emp2016: job[5],
              emp2026: job[6],
              delta_10: job[7],
              delta_per_10: job[8],
              openings: job[10],
              wage_index: job[12],
              median_wage: job[13],
              median_wage_h: job[14],
              median_wage_ex: job[15],
              req_education: job[16],
              req_education_level: job[17],
              related_exp: job[18],
              related_exp_level: job[19],
              req_training: job[20],
              overall_index: job[22]
            })) || [];

          callback({
            jobs
          });
        },
        response => {
          callback(false, response.result.error);
        }
      );
  });
}
